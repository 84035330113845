<script setup>
const props = defineProps(['imageUrl', 'imageSet', 'name']);

const srcSet = {};

if (props.imageSet) {
    props.imageSet.forEach(el => {
        if (!srcSet[el.ext]) {
            srcSet[el.ext] = [
                el.url + ' ' + el.size + 'w'
            ];
        } else {
            srcSet[el.ext].push(el.url + ' ' + el.size + 'w');
        }
    });
}

const srcStrings = ref({});

const updateSource = () => {
    let data = {};

    ['webp', 'png'].forEach(el => {
        if (srcSet[el]) {
            data[el] = srcSet[el].join(', ');
        }
    });

    srcStrings.value = data;
};

updateSource();

watch(() => props.imageSet, (val) => updateSource())

</script>

<template>
    <div v-if="imageSet && imageSet.length > 0" class="product-picture ratio ratio-1x1 m-auto">
        <picture>
            <source v-for="key in Object.keys(srcStrings)" :srcset="srcStrings[key]" sizes="500px"
                :type="`image/${key}`">
            <img :src="imageUrl" :alt="name" :title="name">
        </picture>
    </div>
    <div v-else class="product-image ratio ratio-1x1 m-auto" :style="{ 'background-image': `url(${imageUrl})` }"></div>
</template>

<style lang="scss" scoped>
.product-picture {
    image-rendering: auto;
    user-select: none;
    -webkit-user-select: none;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    transform: scale(1.01);
    pointer-events: none;
    user-select: none;

    img {
        width: 100%;
        max-height: 100%;
        pointer-events: none;
        user-select: none;
        object-fit: contain;
    }
}
</style>